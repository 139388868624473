import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Flex, Text, Box} from 'rebass/styled-components';
import Fade from 'react-reveal/Fade';
import { cleanProjectData } from '../utils';
import Banner from '../components/Banner';
import Layout from '../components/Layout';
import ButtonNav from '../components/ButtonNav';
import Section from '../components/Section';

const ECommerce = () => { 
  const projectData = useStaticQuery(graphql`
    query  {
     allContentfulProject(filter: {projectUrl: {eq: "e-commerce"}}) {
      ...projectDataFragment
     }
   }`)
   const data = cleanProjectData(projectData);
   const {
      bannerImage,
      challengeContent,
      methodsContent,
      name,
      deliverablesContent,
      outcomesContent,
   } = data;

  return (
    <Layout>
      <Banner src={bannerImage} title={name} condensed />
      <Section.Container id="about">
        <Fade>
          <Section.HeaderLeft name="THE CHALLENGE" />
          <Flex justifyContent="center" flexWrap='wrap' mb={[2,6]}>
            <Box width={[1, 1/2]} px={4} pb={2} mb={4}>
              {challengeContent[0]}
            </Box>
            <Box width={[1, 1/2]} px={4} pb={2} mb={4}>
              {challengeContent[1]}
              {challengeContent[2]}
              {challengeContent[3]}
            </Box>
          </Flex>
        </Fade>

        <Fade>
          <Section.HeaderLeft name="METHODS" />
          <Flex justifyContent="center" flexWrap='wrap'>
            <Box width={[1, 1/2]} px={4} mb={[null,4]}>
              {methodsContent[0]}
              {methodsContent[1]}
            </Box>
            <Box width={[1, 1/2]} px={4} mb={4}>
              {methodsContent[2]}
              {methodsContent[3]}
            </Box>
          </Flex>
        </Fade>
        <Fade>
          <Flex justifyContent="center" flexWrap='wrap' mb={[3,6]}>
            <Box width={[1, 1/2]} px={4} mb={4}>
              {methodsContent[4]}  
            </Box>
            <Box width={[1, 1/2]} px={4} mb={4}>
              <Text 
                as="p" 
                sx={{
                  color: "headingSecondary"
                }}
                fontSize={[2,3]}
                mb={4}
              >
                We found patterns for the site overall and category specific pain points. These points and the associated feelings were brought out in our customer journey map - a central focus when commencing our redesign:
              </Text>
              <Text 
                as="p" 
                sx={{
                  color: "headingSecondary"
                }}
                fontSize={[2,3]}
                mb={4}
              >
                Pain points:   

                <ul>
                  <li>
                    Transparency - costs processes
                  </li>
                  <li>
                    Inefficiency - search results & filtering
                  </li>
                  <li>
                    Unpredictability - 'is it me?'
                  </li>
                  <li>
                    Lack of personalisation - 'it’s not for me'
                  </li>
                </ul>
              </Text>
              <Text 
                as="p" 
                sx={{
                  color: "headingSecondary"
                }}
                fontSize={[2,3]}
              >
                Common feelings:   
                <ul>
                  <li>
                    Frustrated & lost
                  </li>
                  <li>
                    Lack of trust & confidence
                  </li>
                  <li>
                    Defeated
                  </li>
                  <li>
                    Under-valued
                  </li>
                </ul>
              </Text>
            </Box>
          </Flex>
        </Fade>

        <Fade>
          <Section.HeaderLeft name="DELIVERABLES" />
          <Flex justifyContent="center" flexWrap='wrap' mb={[null,5]}>
            <Box width={1} px={4} pb={2} mb={4}>
              {deliverablesContent[0]}
            </Box>
          </Flex>
        </Fade>

        <Fade>
          <Flex justifyContent="center" flexWrap='wrap' mb={5}>
            <Box width={[1,1/2]} px={4} pb={2} mb={4}>
              {deliverablesContent[1]}
              {deliverablesContent[2]}
            </Box>
            <Box width={[1,1/2]} px={4} pb={2} mb={4}>
              {deliverablesContent[3]}
            </Box>
          </Flex>
        </Fade>
        <Fade>
          <Section.HeaderLeftSmall name="AUTO BID FLOW WIREFRAMES" />
          <Flex justifyContent="center" flexWrap='wrap' mb={5}>
            <Box width={[1,1/2,1/4]} px={4} pb={2} mb={4}>
              {deliverablesContent[8]}
            </Box>
            <Box width={[1,1/2,1/4]} px={4} pb={2} mb={4}>
              {deliverablesContent[11]}
            </Box>
            <Box width={[1,1/2,1/4]} px={4} pb={2} mb={4}>
              {deliverablesContent[12]}
            </Box>
            <Box width={[1,1/2,1/4]} px={4} pb={2} mb={4}>
              {deliverablesContent[13]}
            </Box>
          </Flex>
        </Fade>

        <Fade>
          <Section.HeaderLeft name="OUTCOMES" />
          <Flex justifyContent="center" flexWrap='wrap'>
            <Box width={[1, 2/5]} px={4} pb={2} mb={4}>
              {outcomesContent[0]}
            </Box>
            <Box width={[1/2,1/5]} px={[3,4,4]} pb={2} mb={4}>
              {outcomesContent[1]}
            </Box>
            <Box width={[1/2,1/5]} px={[3,4,4]} pb={2} mb={4}>
              {outcomesContent[2]}
            </Box>
            <Box display={['none','block']} width={[null,1/5]} px={4} pb={2} mb={4}>
              {outcomesContent[3]}
            </Box>
            <ButtonNav 
              link="/artificial-intelligence"
              text="Next Project"
            /> 
          </Flex>
        </Fade>
      </Section.Container>
    </Layout>
    )
  }

export default ECommerce;




import { Image, Text } from 'rebass/styled-components';
import React, { Fragment } from 'react';
import { BLOCKS } from "@contentful/rich-text-types"

const richTextOptions = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { description, file, title} = node.data.target.fields;
        const { url } = file["en-US"]; 
        const imgDescription = description && description["en-US"]; 
        const imgTitle = title["en-US"];
        return (
          <Fragment>
            <Image alt={imgTitle} src={url} width={1} />
            { imgDescription && (
              <Text 
                as="p"
                sx={{
                  color: "headingSecondary",
                  paddingTop:"10px", 
                  textAlign:"center",
                }}
                fontSize={[2,3]}
              >
                {imgDescription}
              </Text>
            ) }
          </Fragment>
        )
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
              <Text 
                py="2"
                as="p" 
                sx={{
                  color: "headingSecondary"
                }}
                fontSize={[2,3]}
              >
                {children}
              </Text>
            )
          }   
    },
  };

  export default richTextOptions;

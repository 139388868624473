
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import richTextOptions from './richTextOptions';
/* eslint-disable import/prefer-default-export */

export const cleanProjectData = (projectData) => {
  const { allContentfulProject } = projectData;
  const project = allContentfulProject.edges[0].node;
  const { banner, challenge, deliverables, methods, name, outcomes } = project;
  return {
    bannerImage: banner.file.url,
    challengeContent: documentToReactComponents(challenge.json,richTextOptions),
    deliverablesContent: documentToReactComponents(deliverables.json,richTextOptions),
    name,
    methodsContent: documentToReactComponents(methods.json,richTextOptions),
    outcomesContent: documentToReactComponents(outcomes.json,richTextOptions)
  }
} 